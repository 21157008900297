import React, { useState, useEffect } from 'react';
import PageLoader from './helpers/PageLoader';
import './App.css';
import { events } from './helpers/AppHelpers';
import { ChatGPTComponent } from './components/ChatGPTComponent';

function App() {
  const [pathname, setPathname] = useState(window.location.pathname);

  useEffect(() => {
    function handlePopstate() {
      setPathname(window.location.pathname);
    }

    events.on('popstate', handlePopstate);
    window.addEventListener('popstate', handlePopstate);

    return () => {
      events.removeListener('popstate', handlePopstate);
      window.removeEventListener('popstate', handlePopstate);
    };
  }, []);

  return (
    <>
      <PageLoader pathname={pathname} />

      <div><b>All areas above are configurable from Magnoria, so now React SPA becomes a CMD-driven application.</b></div><br></br>

      <ChatGPTComponent></ChatGPTComponent>
      
      <footer>
        This is the footer space for Picton Mahoney POC
        <br />
        Copyright © 20223
      </footer>
    </>
  );
}

export default App;
