import React, {useState} from 'react'
import axios from 'axios';

export const ChatGPTComponent = () => {
  const HTTP = 'http://pmh.poc.majesticdev.ca/chat';
  const [prompt, setPrompt] = useState('');
  const [response, setResponse] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("http:", HTTP, 'prompt: ', prompt);
    axios.post(`${HTTP}`, { prompt })
    .then((res) => {
      setResponse(res.data);
    })
    .catch((error) => {
      console.log(error);
    });
  };

  const handlePrompt = (e) => {
    setPrompt(e.target.value);
  };


  return (
    <div className="chat-gpt">
      <h1>ChatGPT usage demonstration</h1>
      <p>This part is not managed by Magnolia, the purpose of this section<br></br>is to demonstrate the use of React with the ChatGPT API.</p>

      <div className="form">
        <form onSubmit={handleSubmit}>
          <input type='text' name="theAsk" autoComplete='off' placeholder="Ask me anything... Type here your question and press Enter &#9094;" value={prompt} onChange={handlePrompt}></input>
        </form>
      </div>

      <div className="chat-gpt-response">{response ? response : ';)'}</div>
    </div>
  )
}
